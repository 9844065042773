import Image from "App/Components/UI/Image"
import SearchFilter from "App/Components/UI/SearchFilter"
import Table from "App/Components/UI/Table"
import TableHeader from "App/Components/UI/Table/Components/TableHeader"
import ToggleSwitch from "App/Components/UI/ToggleSwitch"
import tableSort from "App/helpers/tableSort"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ActionButton from "Assets/Images/ActionButton.svg";
import ButtonAction from "App/Components/UI/ButtonAction"
import EyeOn from "Assets/Images/EyeOn.svg";
import ConfirmPopup from "App/Components/UI/ConfirmPopup"
import User from "App/Components/UI/User"
import { useStore } from "App/hooks-store/store"
import useHttp from "App/hooks/use-http"
import Pagination from "App/Components/UI/Pagination"
import { LIST_LIMIT } from "App/constants"

const actionMenu = [{
    label: 'View',
    action: 'view',
    src: EyeOn
}
]

const paginationConfig = {
    perPage: LIST_LIMIT
}

const NFTDrop = () => {
    const navigate = useNavigate();
    const dispatch = useStore(false)[1];
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [ShowRemovePopup, setShowRemovePopup] = useState({ mode: false })
    const [ShowAcceptPopup, setShowAcceptPopup] = useState({ mode: false })
    const { triggerAPI } = useHttp();
    const [data, setData] = useState();
    const [search, setSearch] = useState('')
    const [filteredData, setFilteredData] = useState({})
    const { id } = useParams()
    const [sort, setSort] = useState({ direction: '', sortKey: 'name' });
    const [initial, setInitial] = useState(true);
    const [nftDropMenu, setNftDropMenu] = useState([
        {
            label: 'Show Displayed on Home',
            action: 'displayedFrames',
            selected: false,
            type: 'displayedFrames'
        },
        {
            label: 'Date Range',
            action: 'dateRange',
            selected: false,
            popup: true
        }
    ])
    const onSortHandler = useCallback((sortKey) => {
        const dir = sort.sortKey === sortKey ? sort.direction : '';
        const direction = tableSort(dir)
        setSort({ direction, sortKey })
    }, [sort])
    const [list, setList] = useState();

    const onPageChangeHandler = (page) => {
        setPage(page)
                const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }
    const onFilterData = (e) => {
        setFilteredData(e)
    }

    const nftDetailsHanlder = (e) => {
        const subId = e?.currentTarget?.dataset?.galleryid
        if (id) {
            navigate(`/dashboard/gallery-management/creator-gallery/details/${subId}`);
        }
        else {
            navigate(`details/${subId}`);

        }
    }

    const actionHandler = (e) => {
        setData(e)
        if (e.action === 'view') {
            const idValue = e.row.id
            if (id) {
                navigate(`/dashboard/gallery-management/creator-gallery/details/${idValue}`);
            }
            else {
                navigate(`details/${idValue}`);

            }
        }
        else if (e.action === 'Reject') {
            setShowRemovePopup({ mode: true })
        }
        else {
            setShowAcceptPopup({ mode: true })
        }
    }

    const searchHandler = (e) => {
        setSearch(e)
    }

    const onCloseRemovePopup = (e) => {
        setShowRemovePopup({ mode: false })
        if (e) {
            getVerified()
        }
    }

    const onCloseAcceptPopup = (data) => {
        setShowAcceptPopup({ mode: false })
        if (data) {
            getVerified()
        }
    }

    const getNFDropListResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data
        setList(list)
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])

    const getNFDropList = useCallback(() => {
        dispatch('showSpinner');
        const filter = {
            sortBy: sort.sortKey,
            orderBy: sort.direction,
            ...filteredData
        }
        if (id) {
            triggerAPI({
                url: `admin/user/galleries/${id}`, data: {
                    page: page,
                    items: paginationConfig.perPage,
                    search: `${search}`, filter
                }, method: 'post'
            }, getNFDropListResult);
        }
        else {
            triggerAPI({
                url: `gallery/creators/gallery/get`, data: {
                    page: page,
                    items: paginationConfig.perPage,
                    search: `${search}`, filter
                }, method: 'post'
            }, getNFDropListResult);
        }

    }, [dispatch, filteredData, getNFDropListResult, id, page, search, sort.direction, sort.sortKey, triggerAPI])

    const apiResult = useCallback((res) => {
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
        getNFDropList()
    }, [dispatch, getNFDropList])

    const stopHandler = (e) => {
        e.stopPropagation();
    }

    const getVerified = useCallback(() => {
        dispatch('showSpinner');
        let verifiedData = {};
        if (data?.action === 'Accept') {
            verifiedData = {
                id: data?.row?.id,
                verificationStatusId: 2
            }
        }
        else {
            verifiedData = {
                id: data?.row?.id,
                verificationStatusId: 3
            }
        }

        triggerAPI({
            url: `admin/user-nft-drop/verify`, data: verifiedData, method: 'post'
        }, apiResult);
    }, [dispatch, data, triggerAPI, apiResult])

    const toggleSwitch = (id, checked) => {
        getshowOnInfoDesk(id, checked);
    }

    const getshowOnInfoDeskResult = (res) => {
        getNFDropList()
        const { message } = res;
        dispatch('hideSpinner')
        dispatch('showToast', { toast: { toastMode: 'success', message } })
    }

    const getshowOnInfoDesk = (id, checked) => {
        dispatch('showSpinner');
        const data = {
            id: id,
            showOnHomePageDisplay: checked
        }
        triggerAPI({
            url: `gallery/display-status/update`, data, method: 'patch'
        }, getshowOnInfoDeskResult);
    }

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getNFDropList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, search, filteredData])

    useEffect(() => {
        if (!initial) {
            getNFDropList();
        }
        setInitial(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 80, top: 10 }} onSearch={searchHandler} setActionMenu={setNftDropMenu} actionMenu={nftDropMenu} onFilterData={onFilterData} data={filteredData} />
                </div>
            </div>
            <div>
                <Table>
                    <thead className="text-left">
                        <tr>
                            <th>
                                <TableHeader label="Preview" />
                            </th>
                            <th>
                                <TableHeader label="Gallery" sort={sort} onSort={onSortHandler} sortKey="name" />
                            </th>
                            <th>
                                <TableHeader label="Creator" onSort={onSortHandler} sortKey="creatorName" />
                            </th>
                            <th>
                                <TableHeader label="Size" sort={sort} />
                            </th>
                            <th>
                                <TableHeader label="Created Date" sort={sort} onSort={onSortHandler} sortKey="createdAt"/>
                            </th>
                            <th>
                                <TableHeader label="Homepage Display" onSort={onSortHandler} sortKey="showOnHomePageDisplay" />
                            </th>
                            <th>
                                <div className="flex justify-end items-center">
                                    <TableHeader label="Actions" />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="gallery-body">
                        {list?.map(nft => {
                            nft.id = nft?.galleryId;
                            let imagePreview = nft?.gallery?.files?.sliders && nft?.gallery?.files?.sliders[0];
                            return (
                                <tr key={nft.id} data-galleryid={nft?.id} onClick={nftDetailsHanlder}>
                                    <td>
                                        <div className="wall-image-gallery" style={{
                                            backgroundImage: `url(${nft?.gallery?.thumbnailBackground?.imageUrl})`
                                        }}>
                                            <Image src={imagePreview ? imagePreview : ''} className="gallery-image screenshot-image" loaderClass="nft-drop-loader" style={{ 'maxWidth': '4rem', 'Height': '4rem' }} />
                                        </div>
                                    </td>
                                    <td>
                                        {nft?.gallery?.name}
                                    </td>
                                    <td>
                                        {nft?.community?.name}
                                    </td>
                                    <td>
                                        {nft?.gallery?.assetsCount}
                                    </td>
                                    <td>{new Date(nft?.gallery?.createdAt)?.toLocaleString()}</td>
                                    <td>
                                        <div className="flex gallery-home justify-center items-center" onClick={stopHandler}>
                                            <ToggleSwitch className="gallery-switch" check={nft?.gallery?.showOnHomePageDisplay} row={nft} onChange={toggleSwitch} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex justify-end items-center" onClick={stopHandler}>
                                            <ButtonAction row={nft} src={ActionButton} menu={actionMenu} onAction={actionHandler} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No Creator Galleries found!</p>
                    </div>}
                <div className="mt-6">
                    <Pagination itemsPerPage={paginationConfig.perPage} total={totalPage} rows={list?.length} onPageChange={onPageChangeHandler} selected={page} />
                </div>

                <ConfirmPopup show={ShowRemovePopup} content='Are you sure want to reject this NFT Drop?' buttonYes="Reject" buttonCancel="Cancel"
                    style={{ 'maxWidth': '39rem' }}
                    title="Reject NFT Drop" cssClass="delete-user" onClose={onCloseRemovePopup}>
                    <div className="flex block-user-card">
                        <User src={data?.row?.previewImageUrl} label={data?.row?.name} email={`Collection Size: ${data?.row?.collectionSize}`} className="nft-user" />
                    </div>
                </ConfirmPopup>

                <ConfirmPopup show={ShowAcceptPopup} content='Are you sure want to accept this NFT Drop?' buttonYes="Approve" buttonCancel="Cancel"
                    style={{ 'maxWidth': '39rem' }}
                    title="Approve NFT Drop" cssClass="nft-accept" onClose={onCloseAcceptPopup}>
                    <div className="flex block-user-card">
                        <User src={data?.row?.previewImageUrl} label={data?.row?.name} email={`Collection Size: ${data?.row?.collectionSize}`} className="nft-user" />
                    </div>
                </ConfirmPopup>
            </div>
        </div >
    )
}

export default NFTDrop